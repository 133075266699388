<template>
  <Header :switch-state="switchState" @tab-state="tabState($event)"/>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <section class="section scroll-active pt-0">
    <div class="container">
      <!-- <component v-bind:is="switchFilterTab(currentTab)" @switch-state-change="onSwitchStateChange($event)" /> -->
      <SwitchFilter @switch-state-change="onSwitchStateChange($event)" />
      <div class="columns is-flex-wrap-wrap">
        <!-- <component v-bind:is="newPostTab(currentTab)" :switch-state="switchState" @force-update="forceUpdate += 1"/> -->
        <NewPost :switch-state="switchState" @force-update="forceUpdate += 1"/>
        <!-- <component v-bind:is="infiniTab(currentTab)" :switch-state="switchState" @force-update="forceUpdate += 1" :key="forceUpdate"/> -->
        <Infini :switch-state="switchState" @force-update="forceUpdate += 1" :key="forceUpdate"/>
      </div>
    </div>
  </section>
</template>

<script>
import Infini from "./components/Infini.vue";
import NewPost from "./components/NewPost.vue";
import Header from "./components/Header.vue";
import SwitchFilter from "./components/SwitchFilter.vue";
// import GetStarted from "./components/GetStarted.vue";

export default {
  data() {
    return {
      forceUpdate: 0,
      switchState: "student",
      currentTab: "home",
    };
  },
  name: "App",
  components: {
    Infini, NewPost, Header, SwitchFilter
  },
  props: {
    unis: String
  },
  methods: {
    onSwitchStateChange(x){
      this.switchState = x;
      this.forceUpdate += 1;
    },
    newPostTab(currentTab) {
      const tabsMap = {"home": "NewPost", "get-started": undefined}
      return tabsMap[currentTab]
    },
    infiniTab(currentTab) {
      const tabsMap = {"home": "Infini", "get-started": "GetStarted"}
      return tabsMap[currentTab]
    },
    switchFilterTab(currentTab) {
      const tabsMap = {"home": "SwitchFilter", "get-started": undefined}
      return tabsMap[currentTab]
    },
    tabState(state){
      this.currentTab = state;
      this.switchState = "student";
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>

<style scoped>
.columns{
  align-items: center;
}
</style>