<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
    integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
    crossorigin="anonymous"
  />
  <div class="animate__animated animate__fadeIn animate__slow column is-one-third" >
    <div class="content">
      <div v-on:click="openModal" :class="buttonStyle">
        <svg viewBox="0 0 210 200" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" class="new-plus">
          <defs>
            <filter id="filter1315" x="-.026339" y="-.27024" width="1.0527" height="1.5405" color-interpolation-filters="sRGB">
            <feGaussianBlur stdDeviation="1.5047274"/>
            </filter>
            <filter id="filter1438" x="-.27024" y="-.026339" width="1.5405" height="1.0527" color-interpolation-filters="sRGB">
            <feGaussianBlur stdDeviation="1.5047274"/>
            </filter>
          </defs>
          <rect x="94.881" y="51.684" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1438)" opacity=".2" stroke-width=".17439"/>
          <rect x="94.881" y="48.509" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
          <rect transform="rotate(90 .52917 -.52917)" x="114.42" y="-170.38" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1315)" opacity=".2" stroke-width=".17439"/>
          <rect transform="rotate(90)" x="110.38" y="-170.12" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
        </svg>
        <span class="post-ad-text">Post my ad</span>
      </div>
    </div>
  </div>

  <div id="new-post-modal" class="modal animate__animated animate__fadeIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head" :class="modalStyle" >
        <p class="modal-card-title">{{ titleText }}</p>
        <button
          v-on:click="closeModal"
          class="delete"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body" :class="modalStyle" >
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Uni email*</label>
              <div class="control has-icons-right">
                <input
                  v-on:keyup="updateUni"
                  id="email"
                  class="input is-danger"
                  type="email"
                  placeholder="Email input"
                />
                <span class="icon is-small is-right">
                  <i class="fas fa-envelope"></i>
                </span>
              </div>
              <p id="emailInvalidMessage" class="help is-danger">This email is invalid</p>
            </div>
          </div>
          <!-- <div class="column is-one-third">
            <div class="field">
              <label class="label">Year*</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select id="year">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="field">
          <label class="label">University*</label>
          <div class="control has-text-centered">
            <span id="uni" class="button is-static">
              Invalid University
            </span>
          </div>
        </div>

        <div class="field">
          <label class="label">Subject Area*</label>
          <div class="control has-text-centered">
            <input id="autoComplete" type="text" tabindex="1"/>
            <!-- <div class="select is-fullwidth">
              <select id="subject">
                <option v-for="subject in subjects" :key="subject">{{ subject }}</option>
              </select>
            </div> -->
          </div>
        </div>

        <div class="field">
          <label class="label">Message*</label>
          <div class="control">
            <textarea
              id = "message"
              class="textarea"
              placeholder="Enter your message..."
              maxlength="300"
              v-on:keydown="charLimit"
              v-on:keyup="charLimit"
            ></textarea>
            <span class="label">You have <span id="charCount">300</span> characters remaining.</span>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot level" :class="modalStyle" >
        <p class="label level-item level-left">We will email you</p>
        <div class="level-item level-right">
          <button v-on:click="submitPost" class="button has-background-white">
            Submit!
          </button>
        </div>
      </footer>
    </div>
  </div>
  
  <!-- Message -->

  <div id="message-modal" class="modal animate__animated animate__fadeIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head" :class="modalStyle">
        <button
          v-on:click="closeModalMessage"
          class="delete align-right"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body has-text-centered" :class="modalStyle">
        <p id="messageText" class="modal-card-title spinner-grow">{{ messageText }}</p>
      </section>
       <footer class="modal-card-foot level" :class="modalStyle"></footer>
    </div>
  </div>
</template>

<script>
import autoComplete from "@tarekraafat/autocomplete.js/dist/js/autoComplete.min.js";
import axios from "axios";
import unisJSON from "@/assets/data/unis.json";
import subjectJSON from "@/assets/data/subjectList.json";

const subjects = subjectJSON.map(e => e.Major);

export default {
  props: ['switchState'],
  emits: ['forceUpdate'],
  data() {
    return {
      subjects: subjects,
      messageText: "",
      titleText: ""
    };
  },
  computed: {
    buttonStyle() {
      if (this.switchState === "student") {
        return "text-box text-box-blue text-box-shadow-dark"
      } else if (this.switchState === "tutor") {
        return "text-box text-box-red text-box-shadow-dark"
      } else {
        return "text-box text-box-blue text-box-shadow-dark"
      }
    },
    modalStyle() {
      if (this.switchState === "student") {
        return "is-blue"
      } else if (this.switchState === "tutor") {
        return "is-red"
      } else {
        return "is-blue"
      }
    },
  },
  beforeMount() {
    this.updateTitle();
  },
  beforeUpdate() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      if (this.switchState === "student") {
        this.titleText = "Need help? Post an ad";
      } else if (this.switchState === "tutor") {
        this.titleText = "Want to help? Post an ad";
      }
    },
    async updateUni() {
      var uniText = document.getElementById("uni");
      var email = document.getElementById("email").value;
      var pattern = /(?:.+)(@.+)/;
      // RFC 2822
      var validPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (validPattern.test(email)) {
        document.getElementById("email").classList.remove("is-danger");
        document.getElementById("emailInvalidMessage").style.visibility = "hidden";
      } else {
        document.getElementById("email").classList.add("is-danger");
        document.getElementById("emailInvalidMessage").style.visibility = "visible";
      }
      try {
        var uniPart = pattern.exec(email)["1"];
      } catch (err) {
        // No match found
      }
      const uniObj = unisJSON.find((element) => uniPart == element.domains);
      if (typeof uniObj != "undefined") {
        uniText.textContent = uniObj.name;
      } else if (typeof uniPart != "undefined") {
        uniText.textContent = uniPart;
      } else {
        uniText.textContent = "Invalid University";
      }
    },
    async submitPost() {
      var vm = this;
      var email = document.getElementById("email").value;
      // var year = document.getElementById("year").value;
      var uniText = document.getElementById("uni").textContent;
      var subject = document.getElementById("autoComplete").value;
      var message = document.getElementById("message").value;
      
      axios
        .post(
          "https://api.atuuta.org/register-" + this.switchState,
          JSON.stringify({
            email: email,
            uni: uniText,
            subject: subject,
            desc: message
          })
        )
        .then(function (response) {
          // console.log(response);
          document.getElementById("messageText").classList.remove("spinner-grow");
          vm.messageText = response.data;
        })
        .catch(function (error) {
          // console.log(error);
          document.getElementById("messageText").classList.remove("spinner-grow");
          vm.messageText = error.data;
        });
      
      this.closeModal();
      this.openModalMessage();

    // axios
    //   .get("https://api.atuuta.org/data-student")
    //   .then(function (response) {
    //     console.log(response["data"][0]);
    //   })
    },
    openModal() {
      var newPostModal = document.getElementById("new-post-modal");
      newPostModal.classList.remove("animate__fadeOut");
      newPostModal.classList.add("is-active");

      // AutocompleteJS
      const autoCompleteJS = new autoComplete({
        data: {
          src: subjectJSON,
          key: ["Major"],
          },
          placeHolder: "Search for Subject Area",
          highlight: true,
          maxResults: 5,
          resultItem: {
            content: (data, element) => {
              // const key = Object.keys(data.value).find((key) => data.value[key] === element.innerText);
              element.style = "display: flex; justify-content: space-between;";
              element.innerHTML = `<span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                ${element.innerHTML}</span>
                <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
              ${data.value.Major_Category}</span>`;
            }
          },
          noResults: (dataFeedback, generateList) => {
            // Generate autoComplete List
            generateList(autoCompleteJS, dataFeedback, dataFeedback.results);
            // No Results List Item
            const result = document.createElement("li");
            result.setAttribute("class", "no_result");
            result.setAttribute("tabindex", "1");
            result.innerHTML = `<span style="font-weight: 100; color: rgba(0,0,0,.5);">Create and use subject "${dataFeedback.query}"</span>`;
            document
              .querySelector(`#${autoCompleteJS.resultsList.idName}`)
              .appendChild(result);
          },
          onSelection: (feedback) => {
            const selection = feedback.selection.value[feedback.selection.key];
            document.querySelector("#autoComplete").value = selection;
            // console.log(feedback);
          },
        },
      );
    },
    closeModal() {
      var newPostModal = document.getElementById("new-post-modal");
      newPostModal.classList.add("animate__fadeOut");
      setTimeout(() => newPostModal.classList.remove("is-active"), 1500);
    },
    openModalMessage() {
      var newPostModal = document.getElementById("message-modal");
      newPostModal.classList.remove("animate__fadeOut");
      newPostModal.classList.add("is-active");
    },
    closeModalMessage() {
      var newPostModal = document.getElementById("message-modal");
      newPostModal.classList.add("animate__fadeOut");
      setTimeout(() => newPostModal.classList.remove("is-active"), 1500);
      this.$emit("force-update");
    },
    charLimit(){
      const charCount = document.getElementById("message").textLength;
      document.getElementById("charCount").innerText =  300 - charCount;
    },
  },
};
</script>

<style lang="scss" scoped>
%all-text {
  font-weight: bold;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.5rem;
}

.text-box {
  @extend %all-text;

  margin: 1.5rem 0 30px 0;
  padding: 0.5rem;
  display: block;
  border-radius: 30px;
  font-size: 5rem;
  text-align: center;
  cursor: pointer;
}


.text-box-shadow-dark {
  -webkit-box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

  &:hover {
    -webkit-box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
  }
}

.text-box-blue {
  background-color: $a-blue;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: $a-blue-dark;
  }

  &:active {
    color: $a-teal;
  }
}

.text-box-red {
  background-color: $a-red;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: $a-red-dark;
  }

  &:active {
    color: $a-pink;
  }
}

.new-plus {
  width: 2em;
}

/* Modal */
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  padding: 2em 3em;
}

.modal-card-head {
  border-bottom: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 0;
}

.modal-card-title,
.label {
  font-weight: bold;
  color: #fff;
}

.input,
.textarea,
.select select,
.button {
  border-radius: 30px;
}

.textarea {
  padding: 20px;
}

.modal-card-foot {
  border-top: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.float-right {
  float: right;
}

.delete {
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
}

.align-right {
  margin-left: auto;
}

.post-ad-text {
  @extend %all-text;
  display: block;
	font-weight: 400;
	color: white;
	font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  margin: -5% 0 0 0;
}

// Spinner

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}
</style>