<template>
  <!-- <img class="bg-waves animate__fadeIn animate__animated animate__slow" src="../assets/atuuta_waves_pink.svg" /> -->
  <section class="hero is-small">
    <div class="top">
      <svg preserveAspectRatio="none" viewBox="0 0 209.81 111.65" xmlns="http://www.w3.org/2000/svg" class="bg-waves-svg animate__fadeIn animate__animated animate__slow">
        <g transform="translate(-.79375 .79375)">
          <path d="m227.3 104.19s-38.894-19.844-63.235-7.6729c-25.156 12.578-85.725 18.256-99.748-6.8792-14.023-25.135 14.288-91.017 39.158-84.138l121.71-1.3229z" :class="waveStyleInvert"/>
        </g>
        <g>
          <path d="m230.72 58.495s-17.226-17.28-50.639 8.9772c-33.413 26.257-66.128-13.659-91.005 20.367-24.877 34.027-37.128 21.83-92.247 0.39535v-99.669h233.65z" fill="#495058"/>
        </g>
        <path d="m193.25-11.626c2.7796 31.931-74.122 26.93-88.715 24.429-14.593-2.5006-44.01-3.0777-65.552 21.928-21.542 25.006-42.157 1.1541-42.157 1.1541v-47.319z" :class="waveStyle"/>
      </svg>
    </div>
    <div class="hero-head has-text-centered animate__animated animate__fadeIn">
      <div class="container">
        <img class="atuuta-a" src="../assets/img/atuuta_a_blue.svg"/>
        <span class="atuuta-title has-text-white">TUUT</span>
        <img class="atuuta-a" src="../assets/img/atuuta_a_red.svg"/>
        <h2 class="subtitle has-text-white">Find other university students who can help you with your studies.<br>No profile, no fees, fully anonymous.</h2>

      </div>
    </div>
    <div class="hero-body">
      <div class="container menu has-text-centered">
        <div class="columns is-centered">
          <!-- <div class="column is-2">
            <span v-on:click="$emit('tab-state', 'home')" :class="menuStyle" class="menu-button has-shadow">Home</span>
          </div> -->
          <div class="column is-2">
            <!-- <span v-on:click="$emit('tab-state', 'get-started')" :class="menuStyle" class="menu-button has-shadow">Get started</span> -->
            <span v-on:click="openModal" :class="menuStyle" class="menu-button has-shadow">Get started</span>
          </div>
        </div>
      </div>
    </div>
  </section>


 <div id="get-started-modal" class="modal animate__animated animate__fadeIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-centered">
        <p class="modal-card-title">Get started</p>
        <button
          v-on:click="closeModal"
          class="delete"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body">
        <span class="uni">1. About</span>
        <div class="text-box text-box-shadow">
          <div class="container has-text-centered atuuta-logo-modal">
            <img class="atuuta-a-modal" src="../assets/img/atuuta_ag_blue.svg"/>
            <h1 class="atuuta-title-modal a-has-text-grey">TUUT</h1>
            <img class="atuuta-a-modal" src="../assets/img/atuuta_ag_red.svg"/>
          </div>
          <!-- Atuuta is the UK's first free platform for university students to post tutorial ads. -->
          ATUUTA is where university students directly reach out to help other students anonymously. No third parties, no fees, just students to students.
          <div class="columns is-centered">
            <div class="column is-3">
              <a href="mailto:admin@atuuta.com" class="menu-button has-shadow menu-blue">Contact us</a>
            </div>
          </div>
        </div>
        <span class="uni">2. Use the switch button to see tutor and tutee ads</span>
        <div class="text-box text-box-shadow">
          <span>Use this button to see ads from students looking for help!</span>
          <div class="columns is-centered">
            <div class="column is-half">
              <div class="can-toggle demo-rebrand-2">
                <input id="toggleSwitch1" type="checkbox" disabled>
                <label for="toggleSwitch1">
                  <div class="can-toggle__switch has-shadow" data-checked="Be a tutor" data-unchecked="Find a tutor"></div>
                </label>
              </div>
            </div>
          </div>
          <span>Use this button to find students who can help you!</span>
          <div class="columns is-centered">
            <div class="column is-half">
              <div class="can-toggle demo-rebrand-2">
                <input id="toggleSwitch2" type="checkbox" checked disabled>
                <label for="toggleSwitch2">
                  <div class="can-toggle__switch has-shadow" data-checked="Be a tutor" data-unchecked="Find a tutor"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <span class="uni">3. Post my ad</span>
        <div class="text-box text-box-shadow">
            <div class="columns" style="margin: 0 0 1em 0">
              <div class="column has-text-centered">
                Post an ad to <span class="a-has-blue-text has-text-weight-bold">find a tutor</span>
                <div style="display:flex;align-items: center;justify-content: center;">
                  <div class="text-box text-box-blue text-box-shadow-dark" style="width: 80%; margin: 1em 0 0 0;">
                  <svg viewBox="0 0 210 200" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" class="new-plus">
                    <defs>
                      <filter id="filter1315" x="-.026339" y="-.27024" width="1.0527" height="1.5405" color-interpolation-filters="sRGB">
                      <feGaussianBlur stdDeviation="1.5047274"/>
                      </filter>
                      <filter id="filter1438" x="-.27024" y="-.026339" width="1.5405" height="1.0527" color-interpolation-filters="sRGB">
                      <feGaussianBlur stdDeviation="1.5047274"/>
                      </filter>
                    </defs>
                    <rect x="94.881" y="51.684" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1438)" opacity=".2" stroke-width=".17439"/>
                    <rect x="94.881" y="48.509" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
                    <rect transform="rotate(90 .52917 -.52917)" x="114.42" y="-170.38" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1315)" opacity=".2" stroke-width=".17439"/>
                    <rect transform="rotate(90)" x="110.38" y="-170.12" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
                  </svg>
                  </div>
                </div>
              </div>
              <div class="column has-text-centered">
                Post an ad to <span class="a-has-red-text has-text-weight-bold">be a tutor</span>
                <div style="display:flex;align-items: center;justify-content: center;">
                  <div class="text-box text-box-red text-box-shadow-dark" style="width: 80%; margin: 1em 0 0 0;">
                  <svg viewBox="0 0 210 200" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" class="new-plus">
                    <defs>
                      <filter id="filter1315" x="-.026339" y="-.27024" width="1.0527" height="1.5405" color-interpolation-filters="sRGB">
                      <feGaussianBlur stdDeviation="1.5047274"/>
                      </filter>
                      <filter id="filter1438" x="-.27024" y="-.026339" width="1.5405" height="1.0527" color-interpolation-filters="sRGB">
                      <feGaussianBlur stdDeviation="1.5047274"/>
                      </filter>
                    </defs>
                    <rect x="94.881" y="51.684" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1438)" opacity=".2" stroke-width=".17439"/>
                    <rect x="94.881" y="48.509" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
                    <rect transform="rotate(90 .52917 -.52917)" x="114.42" y="-170.38" width="13.363" height="137.11" ry="6.6817" filter="url(#filter1315)" opacity=".2" stroke-width=".17439"/>
                    <rect transform="rotate(90)" x="110.38" y="-170.12" width="13.363" height="137.11" ry="6.6817" fill="#fff" stroke-width=".17439"/>
                  </svg>
                  </div>
                </div>
              </div>
            </div>
            Make sure you are on the right <span class="a-has-blue-text has-text-weight-bold">Find a tutor</span> / <span class="a-has-red-text has-text-weight-bold">Be a tutor</span> page and press the "+"
        </div>
    
        <span class="uni">4. Personal information</span>
        <div class="text-box text-box-shadow">
          Please put down your university email to create a secure student-only environment
          <span class="example-email">example@uni.ac.uk</span>
          Our website is fully anonymous, we will not show your email address anywhere on Atuuta 
        </div>
        
        <span class="uni">5. Communication</span>
        <div class="text-box text-box-shadow">
          <div class="columns is-centered">
            <div class="column is-4">
              <span id="email-button" class="has-shadow email-blue">Email me</span>
            </div>
          </div>
          By using the the "email me" button, our server will send your messages 
          <span class="a-has-blue-text has-text-weight-bold">directly to tutors and students</span>, and the ad poster can see your <span class="a-has-red-text has-text-weight-bold">prefered email</span>
        </div>

        <span class="uni">6. Navigation</span>
        <div class="text-box text-box-shadow">
          <div>
            <span class="badge is-green">Subject</span><span class="uni">Uni</span>
            <div class="text-box text-box-shadow">
            <span><span class="post-number">#10</span>
            <br>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quasi animi fugit incidunt eveniet, dicta dolorem facere dolor suscipit sapiente.
            </span>
         </div>
          Use the ad ID numbers, colour coded subject areas and university names for easy navigation
        </div>
      </div>
      we are social bois
    </section>
  </div>
</div>
  

</template>

<script>
export default {
  props: ['switchState'],
  computed: {
    waveStyle() {
      if (this.switchState === "student") {
        return "wave-blue wave"
      } else if (this.switchState === "tutor") {
        return "wave-red wave"
      } else {
        return "wave-blue wave"
      }      
    },
    waveStyleInvert() {
      if (this.switchState === "student") {
        return "wave-red wave"
      } else if (this.switchState === "tutor") {
        return "wave-blue wave"
      } else {
        return "wave-red wave"
      }      
    },
    menuStyle() {
      if (this.switchState === "student") {
        return "menu-blue"
      } else if (this.switchState === "tutor") {
        return "menu-red"
      } else {
        return "menu-blue"
      }    
    },
  },
  methods: {
    openModal() {
      var newPostModal = document.getElementById("get-started-modal");
      newPostModal.classList.remove("animate__fadeOut");
      newPostModal.classList.add("is-active");
    },
    closeModal() {
      var newPostModal = document.getElementById("get-started-modal");
      newPostModal.classList.add("animate__fadeOut");
      setTimeout(() => newPostModal.classList.remove("is-active"), 1500);
    }
  }
};
</script>

<style lang="scss" scoped>
%all-text{
  font-weight: 400;
  color: white;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
}


.atuuta-title {
  font-family:'TimeBurnder Bold';
  font-size: 4em;
  display: inline-block;
  margin: 0;
}

.atuuta-a {
  height: 3em;
  display: inline-block;
  margin: 0 0.15em;
}

.atuuta-title-modal {
  font-family:'TimeBurnder Bold';
  font-size: 3em;
  display: inline-block;
  margin: 0;
}

.atuuta-a-modal {
  height: 2.23em;
  display: inline-block;
  margin: 0 0.15em;
}

.atuuta-logo-modal{
  // overflow: scroll;
  white-space: nowrap;
}

.subtitle {
  font-family: 'Montserrat', sans-serif;
}

.bg-waves-svg {
  position: relative;
  width: 100vw;
  height: 45vh;
  max-height: 420px;
  z-index: -10;
}

.wave {
  transition: fill 0.5s;
}

.wave-red {
  fill: $a-red;
}

.wave-blue {
  fill: $a-blue;
}

// section {
//   top: 0;
//   position: absolute;
//   margin: auto;
//   // background-color: $a-red-dark; // Used if the image is unavailable
// }

.hero {
  width: 100vw;
  margin: 10vh 0 3vh 0;
  // background-image: url("../assets/atuuta_waves_pink.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: top;
}

.top {
  position: absolute;
  top: 0;
  z-index: -10;
}

.has-shadow {
  -webkit-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

  &:hover {
    -webkit-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
  }
}

.menu-button {
  @extend %all-text;

  padding: 0.25em 0.5em;
  font-size: 1rem;

  color: #ffffff;
  border-radius: 5em;
  cursor: pointer;
}

.menu-blue {
  background-color: $a-blue;
  transition: background-color 0.2s;

  &:hover {
    background-color: $a-blue-dark;
    color: #ffffff;
  }
}

.menu-red {
  background-color: $a-red;
  transition: background-color 0.2s;

  &:hover {
    background-color: $a-red-dark;
  }
}

// copied

.text-box{
  @extend %all-text;
  color: $a-grey;
  margin: 0 0 2em 0;
  padding: 1.5em 1.25em;
  // display: block;
  border-radius: 1.5em;
  background-color: #fff;
  word-wrap: break-word;
}

.badge {
  @extend %all-text;

	display: inline-block;
  padding: .25em .4em;
  margin: 0 0 .4em .5em;
  border-radius: 5em;
  
  line-height: 1;
	// white-space: nowrap;
  word-wrap: break-word;
	vertical-align: baseline;
  text-align: center;
}

.uni{
  @extend %all-text;

  font-weight: 600;
  margin: 0 0 0 1rem;
  display: inline-block;

  color: $a-grey;
}

.has-shadow {
  -webkit-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

  &:hover {
    -webkit-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
  }
}

#email-button {
  // margin: 1em auto 0 auto;
  padding: 0.25em 0.5em;
  
  color: #ffffff;
  border-radius: 5em;
  cursor: pointer;
  // transition: background 0.2s, color 0.2s;

  // &:hover {
  //   background: $a-green;
  // }

  // &:active {
  //   background: $a-teal;
  //   color: $a-red-dark;
  // }
}

.email-blue {
  background: $a-blue;
  transition: background 0.2s, color 0.2s;

  &:hover {
    background: $a-blue-dark;
  }
}

.text-box-shadow {
  -webkit-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
}


/* Modal */
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  @extend %all-text;
  padding: 1em 1.5em;
}

.modal-card-head {
  padding-bottom: 1em !important;
}

.modal-card-head {
  background-color: #fff;
  border-bottom: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 0;
}

.input,
.textarea,
.select select,
.button {
  border-radius: 30px;
}

.textarea {
  padding: 20px;
}

.modal-card-foot {
  border-top: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.float-right {
  float: right;
}

.delete {
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
}

.align-right {
  margin-left: auto;
}


// Switch filter

.can-toggle {
  margin: 1em 0 1em -8%;
}

@mixin can-toggle-branding(
  $can-toggle-off-color: #777, 
  $can-toggle-on-color: #5fc054,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {
  
  input[type="checkbox"] {
    
    // &[disabled] ~ label {
    //   color: rgba($can-toggle-off-color, 0.5);
    // }
    
    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after { color: darken($can-toggle-off-color, 10%); }
      }
    }
    &:hover ~label { color: darken($can-toggle-off-color, 5%); }
    &:checked {
      ~ label {
        &:hover { color: darken($can-toggle-on-color, 3%); }
        
        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after { color: darken($can-toggle-on-color, 5%); }
        }
      }
      
      &:focus, &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after { color: darken($can-toggle-on-color, 10%); }
          }
        } 
      }
    }
  }
  
  label {
    
    .can-toggle__switch {
      cursor: pointer;
      transition: background-color 0.3s $can-toggle-transition;
      background: $can-toggle-off-color;
      &:before { color: $can-toggle-inactive-text; }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color; 
      }
    }
    
  }
}

@mixin can-toggle-appearance 
( 
  $can-toggle-width: 500px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
){
  $can-toggle-switch-width: $can-toggle-width/2;
  
  input[type="checkbox"] {


    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }  
        }
      }

    }
  }
  
  label {
    font-size: $can-toggle-label-font-size;
    
    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;
      
      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size; 
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }
      
      &:after {
        top: $can-toggle-offset; left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset; 
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }
      
      // &:hover {
      //   &:after { box-shadow: $can-toggle-shadow; }
      // }
    } 
  }
}



.can-toggle {
  position: relative;
  *, *:before, *:after { box-sizing: border-box; }
  //overflow: hidden;
  
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;
    
    // &[disabled] ~ label {
    //   pointer-events: none;
    //   .can-toggle__switch { opacity: 0.4; }
    // }
    
    &:checked {
      ~ label {
        
        .can-toggle__switch {
          
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }
        
          &:after {
            content: attr(data-checked);
          }  
        }
      }
      
      &:focus, &:hover {
        ~ label {
        } 
      }
    }
  }
  
  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;
      
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }
      
      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0,0,0);
      }
    }
  }
  
  // Default values for .can-toggle class
  @include can-toggle-branding; 
  @include can-toggle-appearance; 
  
  &.demo-rebrand-2 {
    @include can-toggle-branding
    (
      $a-blue, // Off state color
      $a-red, // On state color
      rgba(white, 0.7),  // Off state text color
      cubic-bezier(0.5, 0, 0.1, 1)  // Transition timing function
    );
    @include can-toggle-appearance
    (
      300px, // Toggle width
      60px,  // Toggle height
      60px,   // Toggle border radius
      2px,   // Offset (distance btw switch and box)
      // 13px,  // Label font size
      24px,  // Switch font size
      0 4px 4px rgba(black, 0.4) // Switch shadow on hover/focus
    ); 
  }
}

.filter-text{
  font-weight: bold;
  color: $a-pink;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
}

// New post

.text-box-blue {
  background-color: $a-blue;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: $a-blue-dark;
  }

  &:active {
    color: $a-teal;
  }
}

.text-box-red {
  background-color: $a-red;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: $a-red-dark;
  }

  &:active {
    color: $a-pink;
  }
}

.text-box-shadow-dark {
  -webkit-box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.2);
  transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

  &:hover {
    -webkit-box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 20px 2px rgba(0,0,0,0.2);
  }
}


// email

.example-email {
  padding: 0.3em 0.9em;
  display: block;
  width: min-content;
  margin: 1em auto;
  border-radius: 1.5em;
  border: 2px solid rgba($a-grey, 0.3);
}

.post-number {
  font-weight: 600;
}

</style>