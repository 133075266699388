<template>
  <div class="animate__animated animate__fadeIn column is-one-third" v-for="ad in ads" :key="ad.post_number">
    <div class="content">
      <span class="badge is-green" :style="'background-color:'+ subjectColor(ad.subject)">{{ ad.subject }}</span>
      <span class="uni">{{ ad.short_uni }}</span>
      <div class="text-box text-box-shadow"><!-- <div :class="adStyle"> --> 


        <!-- <div class="top-row">
          <span :class="badgeStyle" class="badge top-right">#{{ ad.post_number }}</span>  
        </div> -->
          <span><span class="post-number">#{{ ad.post_number }}</span><br>{{ ad.desc }}</span>
          <br>
          <div class="bottom-row">
            <span v-on:click="openEmailModal(ad.post_id)" id="email-button" :class="emailStyle" class="has-shadow">Email me</span>
          </div>
      </div>
    </div>
  </div>

<!-- Modal email -->
  <div id="email-modal" class="modal animate__animated animate__fadeIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head" :class="modalStyle">
        <p class="modal-card-title">Get in touch with this <span v-if="switchState === 'student'">student</span><span v-else>tutor</span></p>
        <button
          v-on:click="closeEmailModal"
          class="delete"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body" :class="modalStyle">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Prefered email*</label>
              <div class="control has-icons-right">
                <input
                  v-on:keyup="updateUniInfini"
                  id="email-infini"
                  class="input is-danger"
                  type="email"
                  placeholder="Email input"
                />
                <span class="icon is-small is-right">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="has-text-white">Your email will be seen by the recipient</span>
              </div>
              <p id="emailInvalidMessageInfini" class="help is-danger">This email is invalid</p>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Message*</label>
          <div class="control">
            <textarea
              id = "message-infini"
              class="textarea"
              placeholder="Enter your message..."
              maxlength="300"
              v-on:keydown="charLimitInfini"
              v-on:keyup="charLimitInfini"
            ></textarea>
            <span class="label">You have <span id="charCount-infini">300</span> characters remaining.</span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot level" :class="modalStyle">
        <p class="label level-item level-left">The<span v-if="switchState === 'student'">&nbsp;student&nbsp;</span><span v-else>&nbsp;tutor&nbsp;</span>will be in touch</p>
        <div class="level-item level-right">
          <button v-on:click="submitPostInfini" class="button has-background-white">
            Submit!
          </button>
        </div>
      </footer>
    </div>
  </div>

  <!-- Message -->

  <div id="message-modal-infini" class="modal animate__animated animate__fadeIn">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head" :class="modalStyle">
        <button
          v-on:click="closeModalMessage"
          class="delete align-right"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body has-text-centered" :class="modalStyle">
        <p id="messageTextInfini" class="modal-card-title spinner-grow">{{ messageTextInfini }}</p>
      </section>
       <footer class="modal-card-foot level" :class="modalStyle"></footer>
    </div>
  </div>


</template>

<script>
import axios from "axios";
import subjectJSON from "@/assets/data/subjectList.json";

export default {
  props: ['switchState'],
  emits: ['forceUpdate'],
  data() {
    return {
      ads: [],
      post_id: null,
      email_valid: false,
      messageTextInfini: "",
      adsLoaded: 0,
    };
  },
  computed: {
    // adStyle() {
    //   if (this.switchState === "student") {
    //     // return "text-box is-pink"
    //     return "text-box-shadow"
    //   } else if (this.switchState === "tutor") {
    //     // return "text-box is-orange"
    //     return "text-box-shadow"
    //   } else {
    //     return "text-box-shadow"
    //   }
    // },
    badgeStyle() {
      if (this.switchState === "student") {
        return "is-teal"
      } else if (this.switchState === "tutor") {
        return "is-violet"
      } else {
        return "is-teal"
      }
    },
    modalStyle() {
      if (this.switchState === "student") {
        return "is-blue"
      } else if (this.switchState === "tutor") {
        return "is-red"
      } else {
        return "is-blue"
      }
    },
    emailStyle() {
      if (this.switchState === "student") {
        return "email-blue"
      } else if (this.switchState === "tutor") {
        return "email-red"
      } else {
        return "email-blue"
      }
    },
    
  },
  methods: {
    async fetchAllAds(type) {
      let ads = await axios
        .get("https://api.atuuta.org/data-" + type)
        .then(response => response.data)
        .catch(error =>  {
          console.log(error);
        })
      // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort ES2015 arrow function
      // ad.sort((a, b) => a.post_number.localeCompare(b.post_number, undefined, {numberic: true}));
      
      ads.sort((a, b) => b.post_number - a.post_number); // Newest first
      // ad.sort((a, b) => a.post_number - b.post_number); // Oldest first
      return ads
    },
    subjectColor(subject) {
      const majors = subjectJSON.map(e => e.Major);
      var majorIndex;
      try {
        majorIndex = majors.indexOf(subject);  
      } catch (error) {
        majorIndex = false;
        console.log("shuuush")
      }


      var badgeColor;
      if (typeof majorIndex === 'undefined' | majorIndex < 0) {
        return
      } else {
        const subjectCat = subjectJSON[majorIndex].Major_Category
        const colorMap = { 
          "Agriculture": "#E88686",
          "Art": "#F4B682",
          "Business": "#E3D28D",
          "Computers & Mathematics": "#B3D69B", 
          "Engineering": "#AED9D8",
          "Humanities": "#8DB8E3",
          "Law": "#A0A0D9",
          "Life Science": "#E0A2D7",
          "Media": "#F2BCBC",
          "Physical Sciences": "#C4ABAB",
          "Social Science": "#D3D3D3",
        }
        badgeColor = colorMap[subjectCat];
      }
      // var badgeColor = "#0FF";
      // function checkSubject(e, subject) {
      //   if (element.Major = subject) {
      //     return element.Color
      //   }
      // }
      // subjectJSON.forEach(element => {});
      // console.log(subject)
      // const subjectObject = subjectJSON.find(e => e.Major = "Economics")
      // console.log(subject)
      // console.log(subjectObject)
      
      // if (subjectObject) { badgeColor = subjectObject.Color; } else { badgeColor = "#0FF"; }
      // console.log(badgeColor);
      return badgeColor
    },
    openEmailModal(post_id) {
      var newPostModal = document.getElementById("email-modal");
      newPostModal.classList.remove("animate__fadeOut");
      newPostModal.classList.add("is-active");
      this.post_id = post_id;
    },
    closeEmailModal() {
      var newPostModal = document.getElementById("email-modal");
      newPostModal.classList.add("animate__fadeOut");
      setTimeout(() => newPostModal.classList.remove("is-active"), 1500);
      // this.$emit("force-update");
    },
    charLimitInfini(){
      const charCountInfini = document.getElementById("message-infini").textLength;
      document.getElementById("charCount-infini").innerText =  300 - charCountInfini;
    },
    async updateUniInfini() {
      var email = document.getElementById("email-infini");
      // RFC 2822
      var validPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (validPattern.test(email.value)) {
        email.classList.remove("is-danger");
        document.getElementById("emailInvalidMessageInfini").style.visibility = "hidden";
        this.email_valid = true;
      } else {
        email.classList.add("is-danger");
        document.getElementById("emailInvalidMessageInfini").style.visibility = "visible";
        this.email_valid = false;
      }
    },
    async submitPostInfini() {
      var vm = this;
      var email = document.getElementById("email-infini").value;
      var message = document.getElementById("message-infini").value;
      // console.log(this.post_id, email, message);
      axios
        .post(
          "https://api.atuuta.org/message",
          JSON.stringify({
            post_id: this.post_id,
            email: email,
            message: message
          })
        )
        .then(function (response) {
          document.getElementById("messageTextInfini").classList.remove("spinner-grow");
          vm.messageTextInfini = response.data;
        })
        .catch(function (error) {
          document.getElementById("messageTextInfini").classList.remove("spinner-grow");
          vm.messageTextInfini = error.data;
        });
      
      this.closeEmailModal();
      this.openModalMessage();

    },
    openModalMessage() {
      var newPostModal = document.getElementById("message-modal-infini");
      newPostModal.classList.remove("animate__fadeOut");
      newPostModal.classList.add("is-active");
    },
    closeModalMessage() {
      var newPostModal = document.getElementById("message-modal-infini");
      newPostModal.classList.add("animate__fadeOut");
      setTimeout(() => newPostModal.classList.remove("is-active"), 1500);
      this.$emit("force-update");
    },
  },
  async mounted() {
    var that = this

    function renderAds(x) {
      that.adsLoaded += x;
      for (let i = 1; i <= x; i++) {
        setTimeout(() => {that.ads.push(ads[0]); ads.shift();}, (500+Math.floor(Math.random()*500)*i))
      }
    }

    // var ads;
    // if (this.switchState === "student") {
    //   ads = await this.fetchAllAds("student");
    // } else if (this.switchState === "tutor") {
    //   ads = await this.fetchAllAds("tutor");
    // } else {
    //   console.error("State error");
    //   ads = await this.fetchAllAds("student");
    // }
    
    var ads = await this.fetchAllAds(this.switchState);

    const adsMax = ads.length;
    
    if (adsMax > 8) {
      renderAds(8);
    } else {
      renderAds(adsMax);
    }
    
    window.addEventListener("scroll", () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      let unloadedAdsLeft = this.adsLoaded < adsMax;

      if (bottomOfWindow && unloadedAdsLeft) {
        if (adsMax - this.adsLoaded > 6 ) {
          renderAds(6);
        } else {
          renderAds(adsMax - this.adsLoaded);
        }  
      }

      // if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      //   // console.log("bottom");
      //   if (adsMax - this.ads.length > 6 ) {
      //     renderAds(6);
      //   } else {
      //     renderAds(adsMax - this.ads.length - 1);
      //   }
      // }
    });
  },
  name: "Infini",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
%all-text{
  font-weight: 400;
  color: white;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
}

.text-box{
  @extend %all-text;

  color: $a-grey;
  margin: 0 0 2em 0;
  padding: 1.5em 1.25em;
  display: block;
  border-radius: 1.5em;
  background-color: #fff;
  word-wrap: break-word;
}

.badge {
  @extend %all-text;

	display: inline-block;
  padding: .25em .4em;
  margin: 0 0 .4em .5em;
  border-radius: 5em;
  
  line-height: 1;
	// white-space: nowrap;
  word-wrap: break-word;
	vertical-align: baseline;
  text-align: center;
}

.uni{
  @extend %all-text;

  font-weight: 600;
  margin: 0 0 0 1rem;

  color: $a-grey;
}

.has-shadow {
  -webkit-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.1);
  transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

  &:hover {
    -webkit-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 15px 20px 1px rgba(0,0,0,0.1);
  }
}

#email-button {
  margin: 1em auto 0 auto;
  padding: 0.25em 0.5em;
  display: table;
  
  color: #ffffff;
  border-radius: 5em;
  cursor: pointer;
  // transition: background 0.2s, color 0.2s;

  // &:hover {
  //   background: $a-green;
  // }

  // &:active {
  //   background: $a-teal;
  //   color: $a-red-dark;
  // }
}

.email-blue {
  background: $a-blue;
  transition: background 0.2s, color 0.2s;

  &:hover {
    background: $a-blue-dark;
  }
}

.email-red {
  background: $a-red;
  transition: background 0.2s, color 0.2s;

  &:hover {
    background: $a-red-dark;
  }
}

.text-box-shadow {
  -webkit-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
}

.top-row {
  position: relative;
}

.bottom-row {
  position: relative;
}

.top-right {
  position: absolute;
  right: -0.5em;
  top: -0.9em;
}

.post-number {
  font-weight: 600;
}

/* Modal */
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  padding: 2em 3em;
}

.modal-card-head {
  border-bottom: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 0;
}

.modal-card-title,
.label {
  font-weight: bold;
  color: #fff;
}

.input,
.textarea,
.select select,
.button {
  border-radius: 30px;
}

.textarea {
  padding: 20px;
}

.modal-card-foot {
  border-top: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.float-right {
  float: right;
}

.delete {
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
}

.align-right {
  margin-left: auto;
}

// Spinner

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}
</style>
